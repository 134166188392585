import { useState } from "react";
import "../styles/Products.scss";
import Modal from "./Modal";

const Products = () => {
  const [isModal, setIsModal] = useState(false);
  const [type, changeType] = useState("Настінні батареї");
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  const [prods, setProds] = useState(initialProds);

  const openModal = (product: any) => {
    setSelectedProduct(product);
    setIsModal(!isModal);
  };

  const toggleDropdown = (categoryId: number, productId: number) => {
    const updatedProds = prods.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          prod_list: category.prod_list.map((item) =>
            item.id === productId ? { ...item, isOpen: !item.isOpen } : item
          ),
        };
      }
      return category;
    });
    setProds(updatedProds);
  };

  const setType = (i: any) => {
    console.log(i);
    if (i === 1) {
      changeType("Настінні батареї");
    } else if (i === 2) {
      changeType("Батареї на колесиках");
    } else if (i === 3) {
      changeType("Наборні Батареї");
    } else if (i === 4) {
      changeType("Інвертори");
    } else if (i === 5) {
      changeType("Сонячні панелі");
    } else if (i === 6) {
      changeType("Комплексне рішення");
    }
  };

  return (
    <div className="Products" id="products">
      {/* {isModal && <Modal openModal={openModal} typeItem={type}></Modal>} */}
      {isModal && <Modal openModal={() => setIsModal(false)} product={selectedProduct} typeItem={type} />}
      <div
        className="ProductsTitleBody"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "products.webp"})` }}
      >
        <h1>Наші товари.</h1>
      </div>
      {/* <div className="Promotion">
        <div className="Promotion_body">
          <div className="Promotion_card">
            <div className="Promotion_bg">
              <img src={process.env.PUBLIC_URL + "prPomotion.webp"} alt="" className="Promotion_img" />
              <div className="Promotion_card_text">
                <h2 className="Promotion_title">Спеціальна пропозиція</h2>
                <p className="Promotion_name">Акумуляторна батарея LPBF48300</p>
                <h3 className="Promotion_text_price">
                  <span className="Promotion_text_old_price">3160,00 ₴</span>
                  2300,00 ₴
                  <span className="Promotion_text_discount">-27%</span>
                </h3>
                <button className="Promotion_btn_card" onClick={openModal}>
                  Замовити
                </button>
                <p className="small_text_bottom">
                  Напишіть в повідомленні "Акція" і наш менеджер одразу сформує вам спеціальну пропозицію.
                </p>
              </div>
            </div>
            <div className="Promotion_blob"></div>
          </div>
        </div>
      </div> */}
      <div className="ProductsBody">
        {prods.map((category) => (
          <div key={category.id} className="ProductsLine">
            <h1>{category.title}</h1>
            <div className="ProductsLineBody">
              {category.prod_list.map((item) => (
                <div key={item.id} className="ProductsLineItem">
                  <img src={process.env.PUBLIC_URL + item.img} alt="" />
                  {item.label === "true" && <div className="products_item_label">ХІТ Продажів.</div>}
                  <h5>{item.title}</h5>
                  <div className="products_item_stats">
                    <div className="products_item_stats_text">{item.text1}</div>
                    <div className="products_item_stats_text">{item.text2}</div>
                    <div className="products_item_stats_text">{item.text3}</div>
                  </div>
                  <p>
                    Ціна: <span>{item.price}</span>
                  </p>
                  {item.documentation && (
                    <div className={`dropdown ,00 ₴{item.isOpen ? "open" : ""}`}>
                      <button
                        className={`dropdown-toggle ,00 ₴{item.isOpen ? "open" : ""}`}
                        onClick={() => toggleDropdown(category.id, item.id)}
                      >
                        Документація
                        <span className={`chevron ,00 ₴{item.isOpen ? "open" : ""}`}>
                          <svg
                            width="14"
                            height="12"
                            viewBox="0 0 24 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.0564 0.886871C21.5555 0.433179 22.3177 0.433359 22.8166 0.887285C23.3869 1.40628 23.3868 2.30353 22.8163 2.82236L13.2889 11.4866C13.1191 11.6419 12.9173 11.7651 12.695 11.8492C12.4728 11.9333 12.2344 11.9766 11.9936 11.9766C11.7528 11.9766 11.5144 11.9333 11.2922 11.8492C11.0699 11.7651 10.8681 11.6419 10.6984 11.4866L1.16585 2.82224C0.595255 2.3036 0.595216 1.40631 1.16577 0.88763C1.66433 0.434387 2.42573 0.434352 2.92434 0.887549L10.6455 7.90551C11.4084 8.59888 12.5733 8.59883 13.3361 7.90538L21.0564 0.886871Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </button>
                      {item.isOpen && (
                        <ul className="dropdown-content">
                          {item.documentation.map((doc: string, index: number) => (
                            <li key={index}>
                              <a href={`/docs/,00 ₴{doc}`} target="_blank" rel="noopener noreferrer">
                                {doc}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  <button
                    className="order-button"
                    onClick={() => {
                      setType(category.id);
                      openModal(item);
                    }}
                  >
                    Замовити
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;

const initialProds = [
  {
    id: 1,
    title: "Настінні",
    prod_list: [
      {
        id: 1,
        img: "pr3.webp",
        title: "Акумуляторна батарея LPBF48200-H",
        text1: "10 кВт-год",
        text2: "200Ah",
        text3: "51.2V",
        price: "71 000,00 ₴",
        label: "true",
        isOpen: false,
        documentation: ["Інструкції-LPBF48200-H.pdf"],
      },
      {
        id: 2,
        img: "pr1.webp",
        title: "Акумуляторна батарея LUX-E-48100LG04",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "37 620,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LUX-E-48100LG04.pdf", "Характеристики-LUX-E-48100LG04.pdf"],
      },
      {
        id: 3,
        img: "pr4.webp",
        title: "Акумуляторна батарея LPBA48200",
        text1: "10 кВт-год",
        text2: "200Ah",
        text3: "51.2V",
        price: "105 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBA48200.pdf", "Характеристики-LPBA48200.pdf"],
      },
      {
        id: 4,
        img: "pr28.png",
        title: "Акумуляторна батарея LUX-E-48100LG03",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "45 100,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LUX-E-48100LG03.pdf"],
      },
      {
        id: 5,
        img: "pr29.png",
        title: "Акумуляторна батарея LPBF24200-M",
        text1: "5 кВт-год",
        text2: "200Ah",
        text3: "25.6V",
        price: "41 800,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBF24200-M.pdf", "Характеристики-LPBF24200-M.pdf"],
      },
      {
        id: 6,
        img: "pr31.png",
        title: "Акумуляторна батарея LPBA48170",
        text1: "8.7 кВт-год",
        text2: "170Ah",
        text3: "48V",
        price: "72 300,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 7,
        img: "pr32.png", ///
        title: "Акумуляторна батарея LPBF24100-M",
        text1: "2.5 кВт-год",
        text2: "100Ah",
        text3: "25.6V",
        price: "23 000,00 ₴", ///
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBF24100-M.pdf", "Характеристики-LPBF24100-M.pdf"],
      },
      {
        id: 8,
        img: "pr33.png", ///
        title: "Акумуляторна батарея LUX-E-48100LG01",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "48 300,00 ₴", ///
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 9,
        img: "pr34.png", ///
        title: "Акумуляторна батарея RW-F10.6 Deye",
        text1: "10.64 кВт-год",
        text2: "208Ah",
        text3: "51.2V",
        price: "1,00 ₴", ///
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-RW-F10-6-Deye.pdf", "Характеристики-RW-F10-6-Deye.pdf"],
      },
      {
        id: 10,
        img: "pr35.png", ///
        title: "Акумуляторна батарея RW-M6.1 Deye",
        text1: "6.14 кВт-год",
        text2: "120Ah",
        text3: "51.2V",
        price: "67 000,00 ₴", ///
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-RW-M6-1-Deye.pdf", "Характеристики-RW-M6-1-Deye.pdf"],
      },
    ],
  },
  {
    id: 2,
    title: "Стаціонарні на колесиках",
    prod_list: [
      {
        id: 1,
        img: "pr5.webp",
        title: "Акумуляторна батарея LUX-E-48250LG03",
        text1: "12.5 кВт-год",
        text2: "250Ah",
        text3: "51.2V",
        price: "111 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LUX-E-48250LG03.pdf", "Характеристики-LUX-E-48250LG03.pdf"],
      },
      {
        id: 2,
        img: "pr6.webp",
        title: "Акумуляторна батарея LPBF48300",
        text1: "15 кВт-год",
        text2: "300Ah",
        text3: "51.2V",
        price: "96 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBF48300.pdf", "Характеристики-LPBF48300.pdf"],
      },
      {
        id: 3,
        img: "pr7.webp",
        title: "Акумуляторна батарея LUX-Y-48300LG01",
        text1: "15 кВт-год",
        text2: "300Ah",
        text3: "51.2V",
        price: "125 700,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LUX-Y-48300LG01.pdf", "Характеристики-LUX-Y-48300LG01.pdf"],
      },
      {
        id: 4,
        img: "pr8.webp",
        title: "Акумуляторна батарея LPBF48350",
        text1: "17.5 кВт-год",
        text2: "350Ah",
        text3: "51.2V",
        price: "146 500,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBF48350.pdf", "Характеристики-LPBF48350.pdf"],
      },
      {
        id: 5,
        img: "pr9.webp",
        title: "Акумуляторна батарея LPBF48500",
        text1: "25.5 кВт-год",
        text2: "500Ah",
        text3: "51.2V",
        price: "196 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LPBF48500.pdf"],
      },
    ],
  },
  {
    id: 3,
    title: "Наборні",
    prod_list: [
      {
        id: 1,
        img: "pr10.webp",
        title: "Акумуляторна батарея FLS 48100-C",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "48 300,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-FLS-48100-C.pdf"],
      },
      {
        id: 2,
        img: "pr11.webp",
        title: "Акумуляторна батарея LUX-X-48100LMG01",
        text1: "5 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "43 800,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-LUX-X-48100LMG01.pdf", "Характеристики-LUX-X-48100LMG01.pdf"],
      },
      {
        id: 3,
        img: "pr26.webp",
        title: "Акумуляторна батарея SE-G5.1 Pro-B Deye",
        text1: "5,12 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "48 100,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SE-G5.1-Pro-B-Deye.pdf"],
      },
      {
        id: 4,
        img: "pr22.webp",
        title: "Високовольтна акумуляторна батарея BOS-GM5.1 Deye",
        text1: "5,12 кВт-год",
        text2: "100Ah",
        text3: "51.2V",
        price: "52 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-BOS-GM51-Deye.pdf"],
      },
      {
        id: 5,
        img: "pr30.png", //////
        title: "Акумуляторна батарея BOS-A Deye",
        text1: "7,68 кВт-год",
        text2: "200Ah",
        text3: "38.4V",
        price: "1,00 ₴", ///
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
    ],
  },
  {
    id: 4,
    title: "Інвертори",
    prod_list: [
      {
        id: 1,
        img: "pr36.png",
        title: "Гібридний інвертор IVEM5048-LV",
        text1: "5 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "20 500,00 ₴",
        label: "true",
        isOpen: false,
        documentation: ["Інструкції-IVEM5048-LV.pdf"],
      },
      {
        id: 2,
        img: "pr23.webp",
        title: "Гібридний інвертор IVEM8048-LV",
        text1: "8 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "32 100,00 ₴",
        label: "true",
        isOpen: false,
        documentation: ["Інструкції-IVEM8048-LV.pdf", "Характеристики-IVEM8048-LV.pdf"],
      },
      {
        id: 3,
        img: "pr12.webp",
        title: "Гібридний інвертор T-REX-5KLP1G01",
        text1: "5 кВт",
        text2: "1 фаза",
        text3: "1 MMPT",
        price: "43 900,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-T-REX-5KLP1G01.pdf", "Характеристики-T-REX-5KLP1G01.pdf"],
      },
      {
        id: 4,
        img: "pr13.webp",
        title: "Гібридний інвертор T-REX-10KLP3G01",
        text1: "10 кВт",
        text2: "3 фази",
        text3: "1 MMPT",
        price: "77 300,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-T-REX-10KLP3G01.pdf", "Характеристики-T-REX-10KLP3G01.pdf"],
      },
      {
        id: 5,
        img: "pr14.webp",
        title: "Гібридний інвертор Apollo-5KLP1G01-MX",
        text1: "5 кВт",
        text2: "1 фазa",
        text3: "1 MMPT",
        price: "63 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-Apollo-5KLP1G01-MX.pdf", "Характеристики-Apollo-5KLP1G01-MX.pdf"],
      },
      {
        id: 6,
        img: "pr24.webp",
        title: "Гібридний інвертор SUN-12K-SG04LP3-EU",
        text1: "12 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "105 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SUN-12K-SG04LP3-EU.pdf"],
      },
      {
        id: 8,
        img: "pr37.png", ////
        title: "Гібридний інвертор SUN-05K-SG03LP1-EU Deye WiFi",
        text1: "5 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "52 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SUN-05K-SG03LP1-EU-Deye-WiFi.pdf"],
      },
      {
        id: 9,
        img: "pr38.png", ////
        title: "Гібридний інвертор SUN-06K-SG05LP1-EU Deye WiFi",
        text1: "6 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "54 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-06K-SG05LP1-EU-Deye-WiFi.pdf",
          "Характеристики-SUN-06K-SG05LP1-EU-Deye-WiFi.pdf",
        ],
      },
      {
        id: 10,
        img: "pr39.png", ////
        title: "Гібридний інвертор SUN-08K-SG01LP1-EU Deye WiFi",
        text1: "8 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "79 500,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-08K-SG01LP1-EU-Deye-WiFi.pdf",
          "Характеристики-SUN-08K-SG01LP1-EU-Deye-WiFi.pdf",
        ],
      },
      {
        id: 11,
        img: "pr40.png", ////
        title: "Гібридний інвертор SUN-10K-SG02LP1-EU-AM3 Deye WiFi",
        text1: "10 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "104 550,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-10K-SG02LP1-EU-AM3-Deye-WiFi.pdf",
          "Характеристики-SUN-10K-SG02LP1-EU-AM3-Deye-WiFi.pdf",
        ],
      },
      {
        id: 12,
        img: "pr41.png", ////
        title: "Гібридний інвертор SUN-12K-SG02LP1-EU-AM3 Deye WiFi",
        text1: "12 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "117 500,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-10K-SG02LP1-EU-AM3-Deye-WiFi.pdf",
          "Характеристики-SUN-10K-SG02LP1-EU-AM3-Deye-WiFi.pdf",
        ],
      },
      {
        id: 13,
        img: "pr42.png", ////
        title: "Гібридний інвертор SUN-16K-SG05LP3-EU-SM2 Deye WiFi",
        text1: "16 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "148 200,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-SUN-16K-SG05LP3-EU-SM2-Deye-WiFi.pdf"],
      },
      {
        id: 14,
        img: "pr43.png", ////
        title: "Гібридний інвертор SUN-20K-SG01HP3-EU-AM2 Deye WiFi",
        text1: "20 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "119 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-20K-SG01HP3-EU-AM2-Deye-WiFi.pdf",
          "Характеристики-SUN-20K-SG01HP3-EU-AM2-Deye-WiFi.pdf",
        ],
      },
      {
        id: 15,
        img: "pr44.png", ////
        title: "Гібридний інвертор SUN-20K-SG05LP3-EU-SM2 Deye WiFi",
        text1: "20 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "172 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-SUN-20K-SG05LP3-EU-SM2-Deye-WiFi.pdf"],
      },
      {
        id: 16,
        img: "pr45.png", ////
        title: "Гібридний інвертор SUN-30K-SG01HP3-EU-BM3 Deye WiFi",
        text1: "30 кВт",
        text2: "3 фази",
        text3: "3 MMPT",
        price: "218 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-30K-SG01HP3-EU-BM3-Deye-WiFi.pdf",
          "Характеристики-SUN-30K-SG01HP3-EU-BM3-Deye-WiFi.pdf",
        ],
      },
      {
        id: 17,
        img: "pr46.png", ////
        title: "Гібридний інвертор SUN-50K-SG01HP3-EU-BM4 Deye WiFi",
        text1: "50 кВт",
        text2: "3 фази",
        text3: "4 MMPT",
        price: "281 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: [
          "Інструкції-SUN-50K-SG01HP3-EU-BM4-Deye-WiFi.pdf",
          "Характеристики-SUN-50K-SG01HP3-EU-BM4-Deye-WiFi.pdf",
        ],
      },
      {
        id: 18,
        img: "pr47.png", ////
        title: "Мережевий інвертор SUN-100K-G03 Deye WiFi",
        text1: "100 кВт",
        text2: "3 фази",
        text3: "6 MMPT",
        price: "163 500,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SUN-100K-G03-Deye-WiFi.pdf", "Характеристики-SUN-100K-G03-Deye-WiFi.pdf"],
      },
      {
        id: 19,
        img: "pr48.png", ////
        title: "Мережевий інвертор SUN-10K-G Deye WiFi",
        text1: "10 кВт",
        text2: "1 фаза",
        text3: "2 MMPT",
        price: "28 500,00 ₴", ////
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 20,
        img: "pr49.png", ////
        title: "Мережевий інвертор SUN-10K-G06P3-EU-AM2 Deye WiFi",
        text1: "10 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "28 400,00 ₴", ////
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 21,
        img: "pr50.png", ////
        title: "Мережевий інвертор SUN-15K-G05 Deye WiFi",
        text1: "15 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "41 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-SUN-15K-G05-Deye-WiFi.pdf"],
      },
      {
        id: 22,
        img: "pr51.png", ////
        title: "Мережевий інвертор SUN-20-G04 Deye WiFi",
        text1: "20 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "45 000,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SUN-20-G04-Deye-WiFi.pdf", "Характеристики-SUN-20-G04-Deye-WiFi.pdf"],
      },
      {
        id: 23,
        img: "pr52.png", ////
        title: "Мережевий інвертор SUN-25K-G05 Deye WiFi",
        text1: "20 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "49 000,00 ₴", ////
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 24,
        img: "pr53.png", ////
        title: "Мережевий інвертор SUN-30-G04 Deye WiFi",
        text1: "30 кВт",
        text2: "3 фази",
        text3: "2 MMPT",
        price: "60 500,00 ₴", ////
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-SUN-30-G04-Deye-WiFi.pdf", "Характеристики-SUN-30-G04-Deye-WiFi.pdf"],
      },
      {
        id: 25,
        img: "pr54.png", ////
        title: "Мережевий інвертор SUN-50K-G03 Deye WiFi",
        text1: "50 кВт",
        text2: "3 фази",
        text3: "4 MMPT",
        price: "101 000,00 ₴", ////
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 26,
        img: "pr55.png", ////
        title: "Мережевий інвертор SUN-80K-G Deye WiFi",
        text1: "80 кВт",
        text2: "3 фази",
        text3: "4 MMPT",
        price: "135 500,00 ₴", ////
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
    ],
  },
  {
    id: 5,
    title: "Сонячні панелі",
    prod_list: [
      {
        id: 1,
        img: "pr17.webp",
        title: "Двосторонній Фотоелектричний модуль DHN-60X16/DG",
        text1: "480W",
        text2: "26 кг",
        text3: "30 Ампер",
        price: "4 200,00 ₴",
        label: "true",
        isOpen: false,
        documentation: ["Характеристики-DHN-60X16-DG.pdf"],
      },
      {
        id: 2,
        img: "pr15.webp",
        title: "Двосторонній Фотоелектричний модуль DHN-78X16/DG",
        text1: "630W",
        text2: "34.2 кг",
        text3: "25 Ампер",
        price: "6 700,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-DHN-78X16-DG.pdf"],
      },
      {
        id: 3,
        img: "pr15.webp",
        title: "Двосторонній Фотоелектричний модуль DHN-72X16/DG",
        text1: "585W",
        text2: "30.5 кг",
        text3: "25 Ампер",
        price: "6 500,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-DHN-72X16-DG.pdf"],
      },
      {
        id: 4,
        img: "pr15.webp",
        title: "Двосторонній Фотоелектричний модуль DHM-72X10",
        text1: "550W",
        text2: "28 кг",
        text3: "25 Ампер",
        price: "5 600,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-DHM-72X10.pdf"],
      },
      {
        id: 5,
        img: "pr18.webp",
        title: "Односторонній Фотоелектричний модуль DHN-54X16/FS",
        text1: "440W",
        text2: "20.1 кг",
        text3: "25 Ампер",
        price: "97,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-DHN-54X16-FS.pdf"],
      },
      {
        id: 6,
        img: "pr27.webp",
        title: "Двосторонній Фотоелектричний модуль DHN-54X16/FS",
        text1: "435W",
        text2: "16.6 кг",
        text3: "25 Ампер",
        price: "4 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-DHN-54X16-FS-435.pdf"],
      },
      {
        id: 7,
        img: "pr64.png",
        title: "Двосторонній Фотоелектричний модуль DHN-54X16DG",
        text1: "440W",
        text2: "23.5 кг",
        text3: "30 Ампер",
        price: "3 900,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
      {
        id: 8,
        img: "pr65.png",
        title: "Фотоелектричний модуль портативний SP-100 CTECHi",
        text1: "100W",
        text2: "3 Ампер",
        text3: "1280х550х4 мм",
        price: "6 600,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
    ],
  },
  {
    id: 6,
    title: "Комутація",
    prod_list: [
      {
        id: 1,
        img: "pr20.webp",
        title: "Кабель для сон. батарей KBE Solar DB+",
        text1: "500 м",
        text2: "IEC 62930",
        text3: "2 PFG",
        price: "26 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-KBE-Solar-DB.pdf"],
      },
      {
        id: 2,
        img: "pr21.webp",
        title: "Кабель для сон. батарей KBE Solar DB+",
        text1: "500 м",
        text2: "IEC 62930",
        text3: "2 PFG",
        price: "26 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Характеристики-KBE-Solar-DB.pdf"],
      },
    ],
  },
  {
    id: 7,
    title: "Комплектуючі",
    prod_list: [
      {
        id: 1,
        img: "pr56.png", ////
        title: "Блок керування кластером високовольтної батареї HVB750V 100A-EU Deye",
        text1: "100 A",
        text2: "IP20",
        text3: "120-750В",
        price: "48 000,00 ₴", ///
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-HVB750V-100A-EU-Deye.pdf"],
      },
      {
        id: 2,
        img: "pr57.png",
        title: "Кронштейн укладання Battery Brackets 3U-model for SE-G5.1 Pro",
        text1: "4 шт",
        text2: "187 мм",
        text3: "макс. укладання 4 поверхи",
        price: "1 050,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [],
      },
      {
        id: 3,
        img: "pr58.png",
        title: "Роликова основа GD-60F roller base для акумуляторних батарей HS51200-10 Delong",
        text1: "міцна",
        text2: "легко переміщати",
        text3: "портативна",
        price: "4 900,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [],
      },
      {
        id: 4,
        img: "pr59.png",
        title: "Стандартна стійка 19 дюймів, (12 шт.) 3U-HRACK Deye",
        text1: "12 шт",
        text2: "19 дюймів",
        text3: "589x590x2240 mm",
        price: "18 500,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-3U-HRACK-Deye.pdf"],
      },
      {
        id: 5,
        img: "pr60.png",
        title: "Стандартна стійка 19 дюймів, (8 шт.) 3U-LRACK Deye",
        text1: "8 шт",
        text2: "19 дюймів",
        text3: "589x590x1640 mm",
        price: "16 400,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-3U-HRACK-Deye.pdf"],
      },
    ],
  },
  {
    id: 8,
    title: "Портативні зарядні станції",
    prod_list: [
      {
        id: 1,
        img: "pr61.png",
        title: "Портативна зарядна станція GT300 Portable Power Station CTECHi",
        text1: "300 Вт",
        text2: "307 Вт-год",
        text3: "4,3 кг",
        price: "9 200,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
    ],
  },
  {
    id: 9,
    title: "Системи зберігання енергії",
    prod_list: [
      {
        id: 1,
        img: "pr62.png",
        title: "Al-W5.1-B Deye",
        text1: "5,12 кВт-год",
        text2: "100Ah",
        text3: "51,2V",
        price: "147 000,00 ₴",
        label: "false",
        isOpen: false,
        documentation: ["Інструкції-Al-W5-1-B-Deye.pdf", "Характеристики-Al-W5-1-B-Deye.pdf"],
      },
      {
        id: 2,
        img: "pr63.png",
        title: "Система зберігання енергії RW-F5.3-2H3 Deye",
        text1: "5,32 кВт-год",
        text2: "100Ah",
        text3: "51,2V",
        price: "1,00 ₴",
        label: "false",
        isOpen: false,
        // documentation: [""],
      },
    ],
  },
];
